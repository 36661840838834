import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Modal from "react-modal";
import reportWebVitals from './reportWebVitals';


Modal.setAppElement('#root');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App />
  </>
);

reportWebVitals();
